/* export const myGetter = (state)=> { return state; } */
export const getInfoUser = (state) => {
  return state.userData;
};

export const getCompany = (state) => {
  return state.company;
};

export const getAccountState = (state) => {
  return state.billing.accountState
}