/* export const myAction = async({commit})=> {} */
import { fetchWithToken } from "@/helpers/fetch";

export const search = async ({ commit }, { payload, page, type }) => {
  commit(type, { isLoading: true });
  let response = null;
  const file = await fetchWithToken(
    "/accountStatus/pdf/marketing",
    payload,
    "POST"
  );
  let fileUrl = null;
  if (file.status !== 404) {
    const fileBlob = await file.blob();
    fileUrl = URL.createObjectURL(fileBlob);
  }

  const fileExcel = await fetchWithToken(
    "/accountStatus/excel/marketing",
    payload,
    "POST"
  );
  let fileUrlExcel = null;
  if (fileExcel.status !== 404) {
    const fileBlobExcel = await fileExcel.blob();
    fileUrlExcel = URL.createObjectURL(fileBlobExcel);
  }

  commit("statesAccount", { fileUrl, fileUrlExcel });

  if (type === "statesAccount") {
    response = await await fetchWithToken(
      "/accountStatus/list/marketing?page=" + page+"&limit=15",
      payload,
      "POST",
      false
    );
  } else {
    response = await fetchWithToken(
      "/paymentHistory/search",
      payload,
      "POST",
      false
    );
  }
  const data = await response.json();
  if (Array.isArray(data)) commit(type, { data: {}, isLoading: false });
  else commit(type, { data: { ...data }, isLoading: false });
};

export const getDocuments = async ({ commit }, { payload }) => {};
