import { createRouter, createWebHistory } from 'vue-router';
import isAuthenticatedGuard from '@/modules/auth/router/auth-guard';
import authRouter from '@/modules/auth/router';
import adminRouter from '@/modules/admin/router';
import userRouter from '@/modules/user/router';
import marketingRouter from '@/modules/marketing/router';


const routes = [
  {
    path: '/',
    ...authRouter,
  },
  {
    path: '/admin',
    beforeEnter: [isAuthenticatedGuard],
    ...adminRouter,
  },
  {
    path: '/usuario',
    beforeEnter: [isAuthenticatedGuard],
    ...userRouter,
  },
  {
    path: '/marketing',
    beforeEnter: [isAuthenticatedGuard],
    ...marketingRouter,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
