import store from '@/store';

const isAuthenticatedGuard = async (to, from, next) => {
  const { status } = await store.dispatch('auth/checkAuthentication');

  const role = localStorage.getItem('role') ?? '';
  const routesAuth = [
    'login',
    'register',
    'forgot-password',
    'new-password',
    'email-confirmation',
    'reset-password',
  ];

  const routesUser = [
    'home',
    'email-confirmated',
    'dashboard-billing',
    'dashboard-accounts',
    'dashboard-collection',
    'dashboard-other-requests',
    'dashboard-profile',
    'wellcome',
  ];

  const routesAdmin = [
    'homeAdmin',
    'digitalLetters',
  ]

  const routesMarketing = [
    'homeMarketing',
  ]

  if (
    status &&
    (routesAuth.includes(to.name) || to.name === 'homeAdmin') &&
    (role === '2' || role === '3')
  ) {
    next({ name: 'dashboard-billing' });
  } else if (
    status &&
    (routesAuth.includes(to.name) || routesAdmin.includes(to.name) ) &&
    role === '1'
  ) {
    next();
  } else if (status && !routesAuth.includes(to.name)) {
    if(role==='1'){
      next({ name: 'homeAdmin' });
    }else{
      next();
    }
  } else if (!status && routesAuth.includes(to.name)) {
    next();
  } else {
    next({ name: 'login' });
  }
};

export default isAuthenticatedGuard;
