export const loginUser = (state, { token, id, rol }) => {
  state.token = token;
  state.user_id = id;
  state.rol = rol
  localStorage.setItem('x-token', token);
  localStorage.setItem('x-id', id);
  localStorage.setItem('role', rol);
};

export const setToken = (state, { token, id }) => {
  state.token = token;
  localStorage.setItem('x-token', token);
  localStorage.setItem('x-id', id);
};

export const logout = (state) => {
  state.token = '';
  state.user_id = '';
  state.rol = ''

  localStorage.removeItem('x-token');
  localStorage.removeItem('x-id');
  localStorage.removeItem('role');
  localStorage.removeItem('admin-data')
  // localStorage.clear();
};
