export default () => ({
  team: 'Codekillers',
  company: '',
  userData: JSON.parse(localStorage.getItem('x-user')) || {},
  analistData: {},
  charge:{},
  billing: {
    accountState: {
      lastPage: 1,
      lastUpdate: '',
      data: {},
      fileUrl: '',
      fileUrlExcel: ''
    },
    billingReport: {
      lastPage: 1,
      lastUpdate: '',
      data: {},
      fileUrl: '',
    }
  }
});
