/* export const myAction = async({commit})=> {} */
import { fetchWithToken } from "@/helpers/fetch";
import router from "@/router";

export const search = async ({ commit }, { payload, page }) => {
  commit("digitalLettersSearch", { isLoading: true });
  const response = await fetchWithToken(
    "/letter/search?page=" + page,
    payload,
    "POST",
    false
  );
  const data = await response.json();
  data.data.forEach((letter) => {
    letter.isLoadingPDF = false;
  });
  commit("digitalLettersSearch", { ...data, isLoading: false });
};

export const getDocument = async ({ commit }, { id }) => {
  try {
    const response = await fetchWithToken("/letter/pdf", { id }, "POST", false);
    if(response.status !== 404){
        const fileBlob = await response.blob();
        let fileUrl = null;
        let status = false;
        if (fileBlob.type === "application/pdf") {
          fileUrl = URL.createObjectURL(fileBlob);
          status = true;
        }
        return { success: status, fileUrl };
    }
    return { success: false, fileUrl: null };

  } catch (ex) {
    return { success: false, fileUrl: null };
  }


};

export const getClients = async ({ commit, rootState }, { page, limit, search }) => {
    const response = await fetchWithToken(`/client?page=${page}&limit=${limit}&fc_company=${rootState.user.company}&search=${search}`, "GET");
    const data = await response.json()
    return data.data
};

export const updateClient = async ({ commit }, payload) => {
  const response = await fetchWithToken(
    "/client/"+payload.id,
    payload,
    "PUT",
    false
  );
  const data = await response.json();
  return data
};

export const createClient = async ({ commit }, payload) => {
  const response = await fetchWithToken(
    "/client",
    payload,
    "POST",
    false
  );
  const data = await response.json();
  return data
};

export const uploadClients = async ({ commit }, payload) => {
  const formData = new FormData();
  formData.append("file", payload);

  const response = await fetchWithToken(
    "/import/users",
    formData,
    "POST",
    true
  );
  const data = await response.json();
  return data
};

export const viewAsClient = async ({ commit }, userId) => {
  const response = await fetchWithToken(`/client/${userId}/impersonate`,"GET");
  const {token, id, rol} = await response.json();

  const adminData = {
    token:localStorage.getItem('x-token'),
    role: localStorage.getItem('role'),
    id: localStorage.getItem('x-id')
  };

  localStorage.setItem('admin-data', JSON.stringify(adminData));
  commit('auth/loginUser', {
    token:token,
    rol: rol,
    id: id
  },{ root: true })

  router.push('/usuario/facturacion')
  // window.location.reload();
};