export default {
  name: 'auth',
  component: () =>
    import(
      /* webpackChunkName: "Auth Layout" */ '@/modules/auth/layouts/AuthLayout.vue'
    ),
  children: [
    {
      path: '',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/auth/views/login/LoginView.vue'
        ),
    },
    {
      path: '/registro',
      name: 'register',
      component: () =>
        import(
          /* webpackChunkName: "Register View" */ '@/modules/auth/views/register/RegisterView.vue'
        ),
    },
    {
      path: '/olvide-contrasena',
      name: 'forgot-password',
      component: () =>
        import(
          /* webpackChunkName: "ForgotPassword View" */ '@/modules/auth/views/forgot-password/ForgotPasswordView.vue'
        ),
    },
    {
      path: '/nueva-contrasena/:id',
      name: 'new-password',
      component: () =>
        import(
          /* webpackChunkName: "NewPassword View" */ '@/modules/auth/views/new-password/NewPasswordView.vue'
        ),
      props: (route) => {
        return {
          id: route.params.id,
        };
      },
    },
    {
      path: '/confirmacion-correo',
      name: 'email-confirmation',
      component: () =>
        import(
          /* webpackChunkName: "emailConfirmation View" */ '@/modules/auth/views/email-confirmation/EmailConfirmationView.vue'
        ),
    },
    {
      path: '/reestablecer-contrasena',
      name: 'reset-password',
      component: () =>
        import(
          /* webpackChunkName: "RestorePassword View" */ '@/modules/auth/views/reset-password/ResetPasswordView.vue'
        ),
    },
    {
      path: '/correo-enviado',
      name: 'email-confirmation-success',
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmation Success View" */ '@/modules/auth/views/email-confimation-success/EmailConfirmationSuccessView.vue'
        ),
    },

    {
      path: ':pathMatch(.*)*',
      redirect: {
        name: 'login',
      },
    },
  ],
};
