import { createStore } from 'vuex';
import auth from '@/modules/auth/store';
import admin from '@/modules/admin/store';
import user from '@/modules/user/store';
import marketing from '@/modules/marketing/store';


export default createStore({
  modules: { auth, admin, user , marketing },
});
