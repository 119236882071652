import { fetchNotToken, fetchApiDevWithToken } from "../../../helpers/fetch";
import { getIpUser } from "../../../helpers/getIpUser";
export const signInUser = async ({ commit }, { email, password }) => {
  const ip = await getIpUser();
  try {
    const recipesPromise = await fetchNotToken(
      "/login",
      {
        email,
        password,
        device: "desktop",
        ip,
      },
      "POST"
    );
    let { errors, status, message, validateEmail, token, id, rol } =
      await recipesPromise.json();
    if (!status) status = "";
    const success = status === "success";
    let validatedEmail = 0;
    if (success) {
    

      commit("loginUser", { token, id, rol });
      return {
        success,
        validatedEmail,
        rol,
      };
    } else if (!success && !errors) {
      if (validateEmail === 1) validatedEmail = 1;

      return {
        success,
        message,
        validatedEmail,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
        validatedEmail,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo.",
    };
  }
};

export const registerByCode = async ({ commit }, fields) => {
  try {
    const registerByCodePromise = await fetchNotToken(
      "/register/start-code",
      fields,
      "POST"
    );

    let { errors, status, message } = await registerByCodePromise.json();
    if (!status) status = "";
    const success = status === "success";

    if (success) {
      return {
        success,
        message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo.",
    };
  }
};

export const registerByPersonalData = async (_, fields) => {
  if (!fields.cellphone) delete fields.cellphone;
  if (!fields.address) delete fields.address;
  try {
    const registerByPersonalDataPromise = await fetchNotToken(
      "/register/start-data",
      fields,
      "POST"
    );
    let { errors, status, message, id } =
      await registerByPersonalDataPromise.json();
    if (!status) status = "";
    const success = status === "success";

    if (success) {
      return {
        success,
        message,
        id,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo.",
    };
  }
};

export const forgotPassword = async (_, field) => {
  try {
    const forgotPasswordPromise = await fetchNotToken(
      "/password/reset",
      field,
      "POST"
    );
    let { status, message, errors } = await forgotPasswordPromise.json();
    if (!status) status = "";
    const success = status === "success";

    if (success) {
      return {
        success,
        message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo.",
    };
  }
};

export const registerPassword = async (_, field) => {
  try {
    const registerPasswordPromise = await fetchNotToken(
      "/register/end",
      field,
      "POST"
    );
    let { status, message, errors } = await registerPasswordPromise.json();
    if (!status) status = "";
    const success = status === "success";
    if (success) {
      return {
        success,
        message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo..",
    };
  }
};

export const registerValidate = async (_, field) => {
  try {
    const registerValidatePromise = await fetchNotToken(
      "/register/validate",
      field,
      "POST"
    );
    const { status, message, errors } = await registerValidatePromise.json();
    if (!status) status = "";
    const success = status === "success";
    if (success) {
      return {
        success,
        message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo..",
    };
  }
};

export const checkAuthentication = ({ commit }) => {
  const token = localStorage.getItem("x-token");
  const id = localStorage.getItem("x-id");
  const rol = localStorage.getItem("role");
  if (!token) {
    commit("logout");
    return { status: false };
  } else {
    commit("loginUser", { token, id, rol });
    return { status: true, rol };
  }
};

export const checkRuc = async (_, { ruc }) => {
  try {
    let validRucPromise = await fetchApiDevWithToken(`ruc/${ruc}`);

    const { success } = await validRucPromise.json();

    return {
      success,
    };
  } catch (ex) {
    return {
      success: false,
      message: "Hubo un problema, vuelva a intentarlo.",
    };
  }
};
