/* export const myMutation = (state)=> {} */

export const statesAccount = (state, payload) => {
    state.statesAccount.search = {
        ...state.statesAccount.search,
        ...payload
    };
}

export const paymentsHistory = (state, payload) => {
    state.paymentsHistory.search = {
        ...state.paymentsHistory.search,
        ...payload
    };
}

export const modalInfoBehavior = (state, payload) => {
    state.paymentsHistory.modals.infoBehavior = payload;
}