/* export const myGetter = (state)=> { return state; } */

export const statesAccount = (state, payload) => {
    return state.statesAccount.search
}

export const paymentsHistory = (state, payload) => {
    return state.paymentsHistory.search
}

export const modalInfoBehavior = (state, payload) => {
    return state.paymentsHistory.modals.infoBehavior
}